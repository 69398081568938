import React from 'react';
import { MonthStreak } from '../../../../components/monthStreak';

const DecemberStreak = () => {
  const days = [
    {
      day: 'Dec 1',
      sleep: { onTime: false, enough: false, upRight: false },
      gotOut: true,
      yesAnd: true,
      exercise: 'travelRest',
      stand: 'yes',
      health: false,
      sawSharpen: 'sharpen',
    },
    {
      day: 'Dec 2',
      sleep: { onTime: false, enough: false, upRight: false },
      gotOut: true,
      yesAnd: true,
      exercise: 'bike',
      stand: 'yes',
      health: false,
      sawSharpen: 'fail',
    },
    {
      day: 'Dec 3',
      sleep: { onTime: false, enough: true, upRight: false },
      gotOut: true,
      yesAnd: true,
      exercise: 'walk',
      stand: 'yes',
      health: false,
      sawSharpen: 'code',
    },
    {
      day: 'Dec 4',
      sleep: { onTime: false, enough: true, upRight: false },
      gotOut: true,
      yesAnd: true,
      exercise: 'bike',
      stand: 'yes',
      health: false,
      sawSharpen: 'code',
    },
    {
      day: 'Dec 5',
      sleep: { onTime: false, enough: true, upRight: false },
      gotOut: true,
      yesAnd: true,
      exercise: 'rest',
      stand: 'dayOff',
      health: false,
      sawSharpen: 'fail',
    },
    {
      day: 'Dec 6',
      sleep: { onTime: false, enough: true, upRight: false },
      gotOut: true,
      yesAnd: true,
      exercise: 'fail',
      stand: 'dayOff',
      health: false,
      sawSharpen: 'code',
    },
    {
      day: 'Dec 7',
      sleep: { onTime: true, enough: true, upRight: false },
      gotOut: true,
      yesAnd: true,
      exercise: 'bike',
      stand: 'yes',
      health: false,
      sawSharpen: 'code',
    },
    {
      day: 'Dec 8',
      sleep: { onTime: false, enough: false, upRight: true },
      gotOut: true,
      yesAnd: false,
      exercise: 'fail',
      stand: 'yes',
      health: false,
      sawSharpen: 'code',
      notes: {
        // eslint-disable-next-line max-len
        yesAnd: 'While I could argue I did something expanding, I had one important must-do thing today and I **did not do it** so failing myself',
      },
    },
    {
      day: 'Dec 9',
      sleep: { onTime: false, enough: false, upRight: true },
      gotOut: true,
      yesAnd: true,
      exercise: 'bike',
      stand: 'yes',
      health: false,
      sawSharpen: 'code',
      notes: {
        // eslint-disable-next-line max-len
        yesAnd: 'I did the thing I was supposed to do yesterday, **but also got other things done**',
      },
    },
    {
      day: 'Dec 10',
      sleep: { onTime: false, enough: false, upRight: true },
      gotOut: false,
      yesAnd: true,
      exercise: 'fail',
      stand: 'yes',
      health: false,
      sawSharpen: 'code',
      notes: {
        // eslint-disable-next-line max-len
        general: 'Cousin Rebecca&rsquo;s memorial service, shiva.  My memory was the "yes, and".  Giving myself a pass on exercise for the time commitment',
      },
    },
    {
      day: 'Dec 11',
      sleep: { onTime: false, enough: false, upRight: true },
      gotOut: true,
      yesAnd: true,
      exercise: 'bike',
      stand: 'yes',
      health: false,
      sawSharpen: 'fail',
      notes: {
        general: 'Traded a bunch of other accomplishments for recovery and making sure I got enough sleep.',
        // eslint-disable-next-line max-len
        stand: 'I had a 6 hour meeting (with breaks).  Could not stand the entire time but accumulated enough stand time this week I also needed some recovery.',
        // eslint-disable-next-line max-len
        sawSharpen: 'Fine to fail on saw sharpen today.  Gotten quite a few days in, and needed a break after a long week of meetings and good coding progress.',
        health: 'Can I claim ordering a new kayak to work out in as health?',
      },
    },
    {
      day: 'Dec 12',
      sleep: { onTime: true, enough: true, upRight: false },
      gotOut: true,
      yesAnd: true,
      exercise: 'fail',
      stand: 'dayOff',
      health: false,
      sawSharpen: 'fail',
      notes: {
        // eslint-disable-next-line max-len
        sleep: 'Went to bed early enough there was no reason not to get up on time.  Fitness watch says I&aps;ve been sleeping poorly',
      },
    },
    {
      day: 'Dec 13',
      sleep: { onTime: true, enough: true, upRight: false },
      gotOut: true,
      yesAnd: true,
      exercise: 'kayak',
      stand: 'dayOff',
      health: false,
      sawSharpen: 'fail',
      notes: {
        // eslint-disable-next-line max-len
        sleep: 'Still sleeping poorly',
        // eslint-disable-next-line max-len
        yesAnd: 'Made Hanukkah dinner with my stepfather for my mother',
      },
    },
    {
      day: 'Dec 14',
      sleep: { onTime: false, enough: true, upRight: false },
      gotOut: true,
      yesAnd: true,
      exercise: 'fail',
      stand: 'yes',
      health: false,
      sawSharpen: 'read',
      notes: {
        sawSharpen: 'Read some of "Faster"',
        // eslint-disable-next-line max-len
        yesAnd: 'Managed to turn shows of exasperation/frustration at work into empathy and productive moving forward',
      },
    },
  ];
  return (
    <MonthStreak days={days} />
  );
};

export default DecemberStreak;
