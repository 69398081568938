import React from 'react';
import PropTypes from 'prop-types';
import {
  List, Table, Icon, Header, Popup,
} from 'semantic-ui-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const streakProps = {
  day: PropTypes.string.isRequired,
  sleep: PropTypes.shape({
    onTime: PropTypes.bool.isRequired,
    enough: PropTypes.bool.isRequired,
    upRight: PropTypes.bool.isRequired,
  }).isRequired,
  gotOut: PropTypes.bool.isRequired,
  yesAnd: PropTypes.bool.isRequired,
  exercise: PropTypes.string.isRequired,
  stand: PropTypes.string.isRequired,
  health: PropTypes.bool.isRequired,
  sawSharpen: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  notes: PropTypes.shape({
    general: PropTypes.string,
    sleep: PropTypes.string,
    gotOut: PropTypes.string,
    yesAnd: PropTypes.string,
    exercise: PropTypes.string,
    stand: PropTypes.string,
    health: PropTypes.string,
    sawSharpen: PropTypes.string,
  }),
};

const colorBool = (boolVal) => (
  boolVal ? 'green' : 'red'
);

const MakeIcon = (iconChoices) => {
  const componentFunc = ({ iconVal, notes }) => {
    const iconCfg = iconChoices[iconVal] || { icon: 'x', color: 'red' };
    const notesFrag = notes
      && (
      <Popup
        trigger={<Icon name="sticky note" size="large" color="yellow" />}
        header="Notes"
        content={<ReactMarkdown plugins={[remarkGfm]}>{notes}</ReactMarkdown>}
        flowing
        hoverable
      />
      );
    return (
      <>
        <Icon name={iconCfg.icon} color={iconCfg.color} size="large" />
        {notesFrag}
      </>
    );
  };
  componentFunc.propTypes = {
    iconVal: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
    ]).isRequired,
    notes: PropTypes.string,
  };
  componentFunc.defaultProps = {
    notes: '',
  };
  return componentFunc;
};

const YesAndIcon = MakeIcon({
  true: { icon: 'checkmark box', color: 'green', legend: 'Expanding' },
  false: { icon: 'x', color: 'red', legend: 'Foreclosing' },
});

const GotOutIcon = MakeIcon({
  true: { icon: 'sign-out', color: 'green', legend: 'Went out' },
  false: { icon: 'sign-in', color: 'red', legend: 'Stayed in' },
});

const HealthIcon = MakeIcon({
  true: { icon: 'doctor', color: 'green', legend: 'Dealt with it' },
  false: { icon: 'x', color: 'red', legend: 'Did nothing' },
});

const StandIcon = MakeIcon({
  yes: { icon: 'checkmark box', color: 'green', legend: 'Made it' },
  no: { icon: 'x', color: 'red', legend: 'Missed' },
  dayOff: { icon: 'sign-out', color: 'grey', legend: 'Day off' },
});

const ExerciseIcon = MakeIcon({
  bike: { icon: 'bicycle', color: 'green', legend: 'Bike ride' },
  walk: { icon: 'paw', color: 'green', legend: 'Walk' },
  kayak: { icon: 'arrows alternate horizontal', color: 'green', legend: 'Kayak' },
  travelRest: { icon: 'pause', color: 'blue', legend: 'Travel rest day' },
  rest: { icon: 'pause', color: 'grey', legend: 'Rest day' },
  fail: { icon: 'x', color: 'red', legend: 'Fail' },
});

const SawSharpenIcon = MakeIcon({
  code: { icon: 'write', color: 'green', legend: 'Coded' },
  sharpen: { icon: 'checkmark box', color: 'green', legend: 'Sharpened' },
  read: { icon: 'book', color: 'green', legend: 'Read' },
  fail: { icon: 'x', color: 'red', legend: 'Did nothing' },
});

const StreakDay = ({
  day, sleep, gotOut, yesAnd, exercise, stand, health, sawSharpen, notes,
}) => (
  <Table.Row key={day}>
    <Table.Cell>{day}</Table.Cell>
    <Table.Cell className="sleep">
      <Icon name="clock" size="large" color={colorBool(sleep.onTime)} />
      <Icon name="stopwatch" size="large" color={colorBool(sleep.enough)} />
      <Icon name="sun" size="large" color={colorBool(sleep.upRight)} />
    </Table.Cell>
    <Table.Cell className="get-out">
      <GotOutIcon iconVal={gotOut} notes={notes && notes.gotOut ? notes.gotOut : ''} />
    </Table.Cell>
    <Table.Cell className="yes-and">
      <YesAndIcon iconVal={yesAnd} notes={notes && notes.yesAnd ? notes.yesAnd : ''} />
    </Table.Cell>
    <Table.Cell className="exercise">
      <ExerciseIcon iconVal={exercise} notes={notes && notes.exercise ? notes.exercise : ''} />
    </Table.Cell>
    <Table.Cell className="stand">
      <StandIcon iconVal={stand} notes={notes && notes.stand ? notes.stand : ''} />
    </Table.Cell>
    <Table.Cell className="health">
      <HealthIcon iconVal={health} notes={notes && notes.health ? notes.health : ''} />
    </Table.Cell>
    <Table.Cell className="saw-sharpen">
      <SawSharpenIcon iconVal={sawSharpen} notes={notes && notes.sawSharpen ? notes.sawSharpen : ''} />
    </Table.Cell>
    <Table.Cell className="notes">{ notes && notes.general ?
      (
        <Popup
          trigger={<Icon name="sticky note" size="large" color="yellow" />}
          header="Notes"
          content={<ReactMarkdown plugins={[remarkGfm]}>{notes.general}</ReactMarkdown>}
          flowing
          hoverable
        />
      ) : ''
    }
    </Table.Cell>
  </Table.Row>
);

StreakDay.propTypes = streakProps;

const noteMsg = 'Any note content will appear when you hover over **any** sticky note';
const Streak = ({ days }) => (
  <div>
    <Table definition>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell>
            <Header size="small">Sleep Schedule</Header>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <Header size="small">Go Outdoors</Header>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <Header size="small">Yes, and</Header>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <Header size="small">Exercise</Header>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <Header size="small">Stand during work</Header>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <Header size="small">Health</Header>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <Header size="small">Sharpen Saw</Header>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <Header size="small">Notes</Header>
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell><Header size="small">Legend:</Header></Table.HeaderCell>
          <Table.HeaderCell>
            <List>
              <List.Item key="onTime">
                <List.Icon name="clock" color="green" />
                <List.Content>In bed by 11</List.Content>
              </List.Item>
              <List.Item key="enough">
                <List.Icon name="stopwatch" color="green" />
                <List.Content>8+ hours</List.Content>
              </List.Item>
              <List.Item key="upRight">
                <List.Icon name="sun" color="green" />
                <List.Content>Up by 7:45am</List.Content>
              </List.Item>
            </List>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <List>
              <List.Item key="gotOut">
                <List.Icon name="sign-out" color="green" />
                <List.Content>Went out</List.Content>
              </List.Item>
              <List.Item key="stayedIn">
                <List.Icon name="sign-in" color="red" />
                <List.Content>Stayed in</List.Content>
              </List.Item>
            </List>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <List>
              <List.Item>
                <List.Icon name="checkmark box" color="green" />
                <List.Content>Expanding</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="x" color="red" />
                <List.Content>Foreclosing</List.Content>
              </List.Item>
            </List>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <List>
              <List.Item>
                <List.Icon name="bicycle" color="green" />
                <List.Content>Bike ride</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="paw" color="green" />
                <List.Content>Walk</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="arrows alternate horizontal" color="green" />
                <List.Content>Kayak</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="pause" color="blue" />
                <List.Content>Travel rest day</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="pause" color="grey" />
                <List.Content>Rest day</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="x" color="red" />
                <List.Content>Fail</List.Content>
              </List.Item>
            </List>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <List>
              <List.Item>
                <List.Icon name="checkmark box" color="green" />
                <List.Content>Made it</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="x" color="red" />
                <List.Content>Missed</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="sign-out" color="grey" />
                <List.Content>Day off</List.Content>
              </List.Item>
            </List>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <List>
              <List.Item>
                <List.Icon name="checkmark box" color="green" />
                <List.Content>Dealt with it</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="x" color="red" />
                <List.Content>Did nothing</List.Content>
              </List.Item>
            </List>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <List>
              <List.Item>
                <List.Icon name="write" color="green" />
                <List.Content>Coded</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="book" color="green" />
                <List.Content>Read</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="checkmark box" color="green" />
                <List.Content>Sharpened</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="x" color="red" />
                <List.Content>Did nothing</List.Content>
              </List.Item>
            </List>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <Popup
              trigger={<Icon name="sticky note" size="large" color="yellow" />}
              header="Notes"
              content={<ReactMarkdown plugins={[remarkGfm]}>{noteMsg}</ReactMarkdown>}
              flowing
              hoverable
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {days.map((day) => StreakDay(day))}
      </Table.Body>
    </Table>
  </div>
);

Streak.propTypes = {
  days: PropTypes.arrayOf(PropTypes.shape(streakProps)).isRequired,
};

const MonthStreak = ({ days }) => (
  <Streak days={days} />
);
MonthStreak.propTypes = {
  days: PropTypes.arrayOf(PropTypes.shape(streakProps)).isRequired,
};

export { MonthStreak, streakProps };
